<template>
    <div class="qrcode-print">
         <el-row :gutter="20">
            <el-col :span="24">
                <h2 align="center" class="mb-20">{{ $t('data.print.printPreview') }}</h2>
            </el-col>

            <el-col :span="24">
                <div id="certView">
                    <div align="center" class="item-qrcode mb-10" v-for="(item, index) in printList" :key="index">
                        <h4>{{item.number}}</h4>
                        <h4 class="mb-10">{{ $t('data.print.certTitle') }}: {{ item.topic }}</h4>
                        <img class="qrcode-img" :src="item.qrcode" alt="">
                        <p>{{ item.member_name || item.member.name_on_certificate }}</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="24" align="center" class="mt-20">
                <el-button v-print="'#certView'">{{ $t('data.print.title') }}</el-button>
            </el-col>

         </el-row>
    </div>
</template>
<script>
import { simpleCerts,certsDetails } from '../../api/index'
export default {
    name: 'qrcodePrint',
    data(){
        return {
            printList:[]
        }
    },
    mounted() {
        this.initPageData();
        
    },
    methods:{
        initPageData(){
            if(this.$route.query.template_id){
                let apiData = {
                    id: this.$route.query.template_id
                }
                simpleCerts({apiData}).then( res => {
                    this.printList = res.data;
                })
            } else if(this.$route.query.cert_id){
                let apiData = {
                    id: this.$route.query.cert_id
                }
                certsDetails({apiData}).then( res => {
                    this.printList.push(res.data);
                    
                })
            }
            
        }
    }
}
</script>

<style>
@media print {
  .item-qrcode{
    -webkit-print-color-adjust:exact;
    -moz-print-color-adjust:exact;
    -ms-print-color-adjust:exact;
    page-break-before:always;
    margin: 0 !important;
  } 
  html, body {
        height: inherit;
    }
  #certView{
    height: inherit !important;
    overflow: inherit;
  }
}
</style>

<style scoped>
@page {  
  size: 250px 290px;
  margin-top: 0mm;
  margin-bottom: 0mm;
  margin-left: 0mm; 
  margin-right: 0mm
}
.item-qrcode{
    padding: 20px 0px
}
.item-qrcode h4{
    font-size: 12px;
}
.item-qrcode .qrcode-img{
    width: 160px;
    height: 160px;
}
.item-qrcode p{
    font-size: 12px;
    width: 160px;
}
</style>